@import "~bootstrap/scss/bootstrap.scss";
@import "../themeData/variables";

$theme-colors-primary: $gradient-start;
$theme-colors-secondary: $gradient-end;

@import "headers";
@import "Contact";

$text-color: rgb(103, 103, 103);

@font-face {
  font-family: "Montserrat", sans-serif;
  // font-family: "exo - Bold";
  // src: url("../fonts/exo/Exo-Bold.ttf");
}
@font-face {
  font-family: "Montserrat", sans-serif;
  // font-family: "exo - Regular";
  // src: url("../fonts/exo/Exo-Regular.ttf");
}
@keyframes overlayAnim {
  from {
    margin-top: 100vh;
  }

  to {
    margin-top: 0;
  }
}
/* Safari */
@-webkit-keyframes overlayAnim {
  from {
    margin-top: 100vh;
  }

  to {
    margin-top: 0;
  }
}
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.item-base-text {
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-weight: Bold;
}

h2.centered {
  text-align: center;
}

.hidden {
  display: none;
}

#root {
  position: absolute;
  top: 0;
  // bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
}

body {
  height: 100%;
  // overflow: hidden;

  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;

  .bodyCont {
    margin-top: 150px;
    padding: 15em 0 0;
    font-size: 15px;
    background-image: url($background);
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    min-width: 100vw;
    min-height: 100vh;
  }

  .bodyLog {
    background: linear-gradient(
      to right,
      $theme-colors-primary 0%,
      $theme-colors-secondary 100%
    );
    min-width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .login {
      display: flex;
      flex-direction: column;
      justify-content: center;

      > form {
        display: flex;
        flex-direction: column;

        > input {
          margin: 20px 0;
          max-width: 80%;
        }
        > button {
          font-family: "Montserrat", sans-serif;
          // font-family: "exo - Regular" !important;
        }
      }
    }

    .loginInfo {
      color: white;
      font-family: "Montserrat", sans-serif;
      // font-family: "exo - Regular" !important;
      font-size: 25px;
      text-align: center;
      margin-top: 10px;
      width: 100%;
    }

    .imgLog {
      width: 80%;
      max-width: 500px;
      height: auto;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  #block-contenu {
    padding: 1em;

    .item-active {
      display: block !important;
    }

    > ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      > li > .itemClass,
      > div > .itemClass {
        display: none;
      }

      > li > .itemClass > div,
      > div > .itemClass > div {
        box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.3);
        border-radius: 25px;
        cursor: pointer;
        height: auto;
        margin-bottom: 25px;
        background-color: rgba(0, 0, 0, 0.8);

        > img {
          border-top-left-radius: 25px;
          border-top-right-radius: 25px;
          height: auto;
          width: 100%;
        }

        > p {
          color: white;
          margin: 0;
          padding: 2.5vh 1vw;
        }
      }
    }
  }

  .overlay-zoom {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(33, 33, 33, 0.6);
    z-index: 9999;
    display: flex;

    .overlay-zoom-content {
      overflow-y: scroll;
      box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.3);
      border-radius: 25px;
      height: 90vh;

      max-height: 90vh;
      max-width: 700px;
      background: white;
      color: $text-color;
      -webkit-animation: overlayAnim 0.5s ease 1;
      /* Safari */
      animation: overlayAnim 0.5s ease 1;

      img {
        padding: 0;
        margin: 0;
        width: 100%;
        max-width: 100%;
        height: auto;
        min-height: auto;
        border: none;
        display: block;
        object-fit: cover;
      }

      .col {
        margin-top: 20px;
      }
    }

    .overlay-zoom-main {
      margin: auto;
    }

    .overlay-zoom-controls-close,
    .overlay-zoom-controls-close > img {
      top: 50px;
      right: 50px;
      border: none;
      background: transparent;
      outline: none;
      position: fixed;
      width: 50px;
      height: 50px;
    }

    .overlay-zoom-controls-close > img {
      border-radius: 55%;
      background: rgba(255, 255, 255, 1);
    }

    .buttonAction {
      position: fixed;
      left: 30%;
      right: 30%;
      text-align: center;
      margin: auto;
      top: 75vh;
      width: auto;

      .loader {
        position: fixed;
        left: 45%;
        margin: auto;
        right: 45%;
      }
    }

    .statusMessage .message {
      display: block;
    }

    .loaderOverlay {
      display: none;

      > .loader {
        animation-duration: 2s;
        border: 6px solid #f3f3f3;
        border-radius: 50%;
        border-top: 6px solid $theme-colors-secondary;
        width: 55px;
        height: 55px;
        -webkit-animation: spin 1s linear 2;
        /* Safari */
        animation: spin 1s linear 2;
      }
    }
  }

  .card {
    margin: auto;
    text-align: center;
    margin-bottom: 50px;
  }

  .cta {
    border-color: $theme-colors-primary;
    background-color: $theme-colors-primary;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .cta:hover,
  .cta:focus,
  .cta:active {
    background-color: $theme-colors-secondary;
    border-color: $theme-colors-secondary;
  }

  .overlay-zoom:not(.is-visible) {
    display: none;
  }

  .profile {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;

    > button {
      margin: 0 auto;

      border-color: $theme-colors-secondary;
      background-color: $theme-colors-secondary;
      font-family: "Montserrat", sans-serif !important;
      // font-family: "exo - Regular" !important;
    }

    > button:hover {
      margin: 0 auto;
      background-color: $theme-colors-secondary;
    }

    > p {
      font-family: "Montserrat", sans-serif !important;
      // font-family: "exo - Regular" !important;
      margin-bottom: inherit;
    }

    .profile-close,
    .profile-close > img {
      top: 10.5em;
      right: 10.5%;
      border: none;
      background: transparent;
      outline: none;
      position: fixed;
      width: 25px;
      height: 25px;
    }
  }
}
@media all and (max-width: 992px) {
  body {
    .bodyCont {
      padding-top: 0em !important;
      // margin-top: 9em !important;
    }
    .overlay-zoom {
      display: block;
      .overlay-zoom-content {
        box-shadow: none;
        border-radius: 0px;
        margin-bottom: 10vw;
        max-width: 100vw;
      }
      .overlay-zoom-controls-close,
      .overlay-zoom-controls-close > img {
        top: inherit;
        bottom: 10px;
        left: 45%;
        right: inherit;
        margin: auto;
        width: 10vw;
        height: auto;
      }
    }

    .bodyProfile {
      text-align: center;
      width: 100%;
    }
  }
}
