// main: main.scss
.contact-content {
	border: 1px solid $gradient-start;
	margin-bottom: 20px;
	margin-left: 0;
	padding-left: 0px;
	padding-right: 0px;
	border-radius: 20px;
	overflow: hidden;

	img {
		max-width: 60%;
		height: auto;
	}

	.infoCol {
		margin-left: 10px;
	}

	.header {
		background-color: $gradient-start;
		margin: 0px;
		color: white;
		padding: 10px;
	}

	.presentation {
		color: gray;
	}
}

@media all and (max-width:992px) {
	.contact-content{
		img {
			margin: auto !important;
		}
	}
}
